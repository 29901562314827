import React, {useEffect, useState} from "react";

import SrvCore from "../../../../utils/_legacy/SrvCore";
import ConstructionDetailsSection from "../detailsSection/ConstructionDetailsSection";
import type ConstructionBodyProps from "./ConstructionBodyProps";
import type ConstructionGUIData from "../../../../types/ConstructionGUIData";
import {useNavigate} from "react-router-dom";
import PathConstants from "../../../../routes/pathConstants";

const ConstructionBody: React.FunctionComponent<ConstructionBodyProps> = ({
  construction,
}) => {
  // Note: refactor source data propagation to not use state
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (construction === null) {
      navigate(PathConstants.NotFound);
    } else {
      const constructionData = SrvCore.getConstructionData(construction);
      setData(constructionData);
    }
  }, [construction]);

  return (
    <>
      {data && (
        <div>
          {data
            .filter((s: any) => s.parent === null)
            .map((section: ConstructionGUIData) => (
              <ConstructionDetailsSection
                key={section?.id}
                section={section}
                constructionId={construction?.id}
                constructionStatus={construction?.status}
                subSections={data.filter((s: any) => s.parent === section?.id)}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default ConstructionBody;
