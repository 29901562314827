import React from "react";
import styles from "./IsaacPill.module.scss";

const IsaacPill: React.FunctionComponent<{
  name: string | undefined;
  value: string | number | undefined;
}> = ({name, value}) => {
  return (
    <div key={name} className={styles.constructionInfoItem}>
      {name && <div className={styles.itemKey}>{`${name}:`} </div>}
      {value !== undefined && <div className={styles.itemValue}>{value}</div>}
    </div>
  );
};

export default IsaacPill;
