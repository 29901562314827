import type ICalculation from "../../../../types/ICalculation";
import type IConstruction from "../../../construction/types/IConstruction";
import type IBeamTab from "./types/IBeamTab";
import type IMastTab from "./types/IMastTab";
import type ISpacerTab from "./types/ISpacerTab";
import type ITab from "./types/ITab";

// Note: This needs refactoring. Most of the logic has just been ported and not adjusted
const useCalculationMastTabs = (
  calculations: ICalculation[],
  construction: IConstruction | undefined,
  selectedCalculationResults: any,
): ITab[] => {
  if (construction === undefined) {
    return [];
  }

  let {masts, beams} = construction;
  const {profilesAreValid, mastResults} = selectedCalculationResults;

  const calculationMastResult = calculations.find(
    c => c.calculationId === selectedCalculationResults.calculationId,
  )?.mastResults;

  masts = masts.map((m: any) => ({
    ...m,
    profileType:
      calculationMastResult?.find((cm: any) => cm.mastId === m.id)
        ?.profileType ?? m.profile,
  }));

  beams = beams.map((b: any) => ({
    ...b,
    type:
      selectedCalculationResults.editableConstructionProperties?.find(
        (ep: any) => ep.entityId === b.id,
      )?.propertyValue ?? b.type,
  }));

  const maxLength =
    mastResults.length > beams.length ? mastResults.length : beams.length;

  const partialResult: ITab[] = [];

  for (let i = 0; i < maxLength; ++i) {
    const mastFoundation = masts[i]?.foundation;
    const foundationType =
      selectedCalculationResults.editableConstructionProperties?.find(
        (ep: any) => ep.entityId === mastFoundation?.id,
      )?.propertyValue ?? mastFoundation?.type;
    const mastDetails =
      i < mastResults.length ? {...mastResults[i], ...masts[i]} : null;
    const beamDetails = i < beams.length ? beams[i] : null;
    const subResults: ITab[] = [];

    if (mastDetails !== null) {
      const mastTab: IMastTab = {
        number: mastDetails.mastNumber,
        profilesAreValid,
        type: mastDetails.profileType,
        foundation: foundationType,
      };
      subResults.push(mastTab);
    }

    if (beamDetails !== null) {
      const beamTab: IBeamTab = {type: beamDetails.type};
      subResults.push(beamTab);
    }

    if (mastDetails !== null && beamDetails !== null) {
      if (mastDetails.position * 2 - 1 >= beamDetails.compartment * 2) {
        subResults.reverse();
      }
    }

    partialResult.push(...subResults);
  }

  const result: ITab[] = [];

  partialResult.forEach((element, index) => {
    result.push(element);
    if (index !== partialResult.length - 1) {
      const spacerTab: ISpacerTab = {isSpacer: true};
      result.push(spacerTab);
    }
  });

  return result;
};

export default useCalculationMastTabs;
