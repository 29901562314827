import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";

import type IsaacNotificationsProps from "./IsaacNotificationsProps";
import useSignalRMessages from "../../services/signalR/hooks/useSignalRMessages";
import useMessageHandle from "./hooks/useMessageHandle";

const IsaacNotifications: React.FunctionComponent<IsaacNotificationsProps> = ({
  userName,
}) => {
  const messages = useSignalRMessages();
  const handleMessage = useMessageHandle();

  useEffect(() => {
    if (messages !== undefined && messages.length > 0) {
      messages.forEach(message => {
        const messageIsForMe =
          message.user !== undefined &&
          message.user === userName &&
          !message.isRead;
        if (messageIsForMe) {
          handleMessage(message);
        }
      });
    }
  }, [messages]);

  return <ToastContainer stacked />;
};

export default IsaacNotifications;
