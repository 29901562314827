import React, {useEffect, useState} from "react";
import {Stack} from "@fluentui/react";

import styles from "./DeselectedElementsPanel.module.scss";
import IsaacList from "../../../../components/isaacList/IsaacList";
import type DeselectedElementsPanelProps from "./DeselectedElementsPanelProps";
import type RenderableDeselectedConstructionElement from "../../../../types/RenderableDeselectedConstructionElement";

const DeselectedElementsPanel: React.FunctionComponent<
  DeselectedElementsPanelProps
> = ({profileCalculationResults, construction}) => {
  const [items, setItems] = useState<RenderableDeselectedConstructionElement[]>(
    [],
  );
  useEffect(() => {
    if (construction !== undefined) {
      const deselectedElements: RenderableDeselectedConstructionElement[] =
        profileCalculationResults?.deselectedConstructionElements ?? [];
      const items: RenderableDeselectedConstructionElement[] =
        deselectedElements.map(d => ({
          compartment: d.compartment,
          entityType: d.entityType,
          position: d.position,
        }));

      setItems(items);
    }
  }, [profileCalculationResults]);

  return (
    <>
      {items.length > 0 && (
        <Stack grow={1} className="page-brake-after">
          {/* TODO: Move all text into translation files */}
          <h3 className={styles.header}>Deselected construction elements</h3>
          <IsaacList<RenderableDeselectedConstructionElement>
            items={items}
            onRenderCell={item => (
              <>
                {item?.position
                  ? `${item?.entityType}(${item?.compartment},${item?.position})`
                  : `${item?.entityType}(${item?.compartment})`}
              </>
            )}
          />
        </Stack>
      )}
    </>
  );
};

export default DeselectedElementsPanel;
