import React from "react";
import {Icon} from "@fluentui/react";

import styles from "../../sidePanel/ConstructionCalculationsPanel.module.scss";

const SpacerTab: React.FunctionComponent = () => {
  return (
    <div className={styles.separator}>
      <Icon iconName="Remove" />
    </div>
  );
};

export default SpacerTab;
