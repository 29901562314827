import useConstructionHook from "../../../hooks/useConstructionHook";
import useIssacToastHook from "../../../hooks/useIssacToastHook";
import type {EditableProperty} from "../../../types/EditableConstruction";
import IsaacToastWrapperType from "../../../types/IsaacToastWrapperType";

const useSaveModifiedConstructionHook = (): ((
  constructionId: string | undefined,
  calculationId: string,
  mastResults: any,
  constructionEditableProps: any,
) => Promise<void>) => {
  const {saveModifiedConstruction} = useConstructionHook();

  const {withToast} = useIssacToastHook();

  const save = async (
    id: string | undefined,
    calculationId: string,
    mastResults: any[],
    constructionEditableProps: EditableProperty[],
  ): Promise<void> => {
    if (id === undefined) {
      throw new Error("Missing id");
    }

    const editableProps: EditableProperty[] = [...constructionEditableProps];

    // todo @Cristi plz check if still needed somewhere
    // mastResults.forEach((mastResult: any) => {
    //   const index = editableProps.findIndex(
    //     (ep: any) => ep.entityId === mastResult.mastId,
    //   );

    //   if (index === -1) {
    //     editableProps.push({
    //       entityId: mastResult.mastId,
    //       propertyName: "Profile",
    //       entityName: "masts",
    //       propertyValue: mastResult.profileType,
    //     });
    //   } else {
    //     editableProps[index] = {
    //       ...editableProps[index],
    //       propertyValue: mastResult.profileType,
    //     };
    //   }
    // });

    // Note: with toast should be moved to top level
    await withToast(
      async () =>
        await saveModifiedConstruction(id, calculationId, editableProps),
      IsaacToastWrapperType.Full,
      undefined,
      `Triggering construction update...`,
      `Update construction triggered. You will receive a notification on completion.`,
      `Failed to trigger construction update!`,
    );
  };

  return save;
};

export default useSaveModifiedConstructionHook;
