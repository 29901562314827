import {useEffect, useState} from "react";

/** Hook that wraps async action to execute
 * @param {Function} asyncFunc - Async function to execute
 * @returns {T} T
 * @returns {boolean} loading
 * @returns {Error} Error
 */
const useAsyncOperation = <T>(
  asyncFunc: () => Promise<T>,
): {result: T | undefined; loading: boolean; error: Error | undefined} => {
  const [result, setResult] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const executeAsyncFunc = async (): Promise<void> => {
      setLoading(true);
      try {
        const response = await asyncFunc();
        setResult(response);
      } catch (error) {
        setError(
          error instanceof Error
            ? error
            : new Error("An unknown error occurred"),
        );
      } finally {
        setLoading(false);
      }
    };

    void executeAsyncFunc();
  }, []);

  return {result, loading, error};
};

export default useAsyncOperation;
