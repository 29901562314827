import {BlobServiceClient, type BlockBlobClient} from "@azure/storage-blob";
import {
  // DefaultAzureCredential,
  type AccessToken,
  type TokenCredential,
} from "@azure/identity";

import type IIsaacBlob from "../types/IIsaacBlob";
import type AuthToken from "../../auth/types/IAuthToken";
// import isDev from "../../../utils/DevDetect";

const getCredentialsBasedOnEnv = (authToken: AuthToken): TokenCredential => {
  // ): TokenCredential | DefaultAzureCredential => {
  // if (isDev()) {
  //   const token: AccessToken = {
  //     token: authToken.accessToken,
  //     expiresOnTimestamp: authToken.expiresOnTimestamp ?? Number.MIN_VALUE,
  //   };
  //   const tokenCredential: TokenCredential = {
  //     getToken: async () => token,
  //   };
  //   return tokenCredential;
  // }

  const token: AccessToken = {
    token: authToken.accessToken,
    expiresOnTimestamp: authToken.expiresOnTimestamp ?? Number.MIN_VALUE,
  };
  const tokenCredential: TokenCredential = {
    getToken: async () => token,
  };
  return tokenCredential;
};

const getServiceClient = (authToken: AuthToken): BlobServiceClient => {
  const credentials = getCredentialsBasedOnEnv(authToken);

  return new BlobServiceClient(
    `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net`,
    credentials,
  );
};

const getBlobClient = (
  containerName: string,
  blobName: string,
  staticToken: AuthToken,
): BlockBlobClient => {
  const blobServiceClient = getServiceClient(staticToken);
  const containerClient = blobServiceClient.getContainerClient(containerName);
  return containerClient.getBlockBlobClient(blobName);
};

const loadFileFromBlob = async (
  containerName: string,
  blobName: string,
  staticToken: AuthToken,
): Promise<string | undefined> => {
  try {
    const blobClient = getBlobClient(containerName, blobName, staticToken);

    const downloadBlockBlobResponse = await blobClient.download();
    const blobData = await downloadBlockBlobResponse.blobBody;

    if (blobData === undefined) {
      throw new Error("Failed to read blob!");
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const blobUrl = window.URL.createObjectURL(blobData);

    return blobUrl;
  } catch (error) {
    console.log(error);
  }
};

const useBlob = (): IIsaacBlob => {
  return {loadFileFromBlob};
};

export default useBlob;
