import React from "react";
import {Checkbox, Icon} from "@fluentui/react";

import styles from "./Editable.module.scss";
import type {IEditableProps} from "./IEditableProps";
import {EditableType} from "../../../types/EditableItem";

const Editable: React.FunctionComponent<IEditableProps> = ({
  value,
  children,
  type,
  isValid,
  isDisabled,
  isEditing,
  handleClick,
  handleBlur,
}) => {
  if (!isEditing) {
    return (
      <div
        onClick={handleClick}
        className={`${styles.container} ${isValid ? "" : styles.error}`}>
        {type === EditableType.CheckBox ? (
          <Checkbox checked={Boolean(value)} disabled={isDisabled}></Checkbox>
        ) : (
          <p className={isDisabled ? styles.disabled : styles.normal}>
            {value}
          </p>
        )}
        {!isDisabled && <Icon className={styles.editIcon} iconName="Edit" />}
      </div>
    );
  }
  return (
    <div className={styles.editingContainer} onBlur={handleBlur}>
      {children}
    </div>
  );
};

export default Editable;
