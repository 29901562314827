// Note: This should be removed
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, {useState} from "react";
import styles from "../IsaacTags.module.scss";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import {useDispatch} from "react-redux";

import type ConfirmTagDeletionDialogProps from "./ConfirmTagDeletionDialogProps";
import type ApiResponse from "../../../services/api/types/ApiResponse";
import IsaacDialog from "../../../components/isaacDialog/IsaacDialog";
import useTagHook from "../../../hooks/useTagHook";
import {removeTag} from "../../../pages/construction/constructionSlice";
import {removeTagFromConstruction} from "../../../pages/constructions/constructionsSlice";
import SrvCore from "../../../utils/_legacy/SrvCore";

const ConfirmTagDeletionDialog: React.FunctionComponent<
  ConfirmTagDeletionDialogProps
> = ({isHidden, handleDismiss, tag, constructionId}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<ApiResponse<any> | undefined>();
  const {deleteTagFromConstruction} = useTagHook();

  const dispatch = useDispatch();

  const handleTagDeletion = async (): Promise<void> => {
    setIsLoading(true);

    if (tag !== undefined && constructionId !== undefined) {
      const response = await deleteTagFromConstruction(constructionId, tag);
      // Note: dependency on sleep should be removed.
      await SrvCore.sleep(2);
      setResult(response);
      if (response.success) {
        dispatch(removeTag(tag));
        dispatch(removeTagFromConstruction({tag, constructionId}));
        handleDismiss();
      }
    }

    setIsLoading(false);
  };

  return (
    <IsaacDialog
      isLoading={isLoading}
      isHidden={isHidden}
      successMessage="Tag was successfully removed."
      errorMessage="Tag was not removed."
      error={result?.errors}
      modalProps={{
        titleAriaId: "NewCalculation",
        subtitleAriaId: "mySubTextId",
        isBlocking: true,
        containerClassName: `ms-dialogMainOverride ${styles.deleteDialog}`,
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Remove tag from construction",
        subText: "Are you sure you want to remove tag from construction?",
      }}
      handleDialogDismiss={handleDismiss}>
      <DialogFooter>
        <PrimaryButton onClick={handleTagDeletion} text="Confirm" />
        <DefaultButton onClick={handleDismiss} text="Cancel" />
      </DialogFooter>
    </IsaacDialog>
  );
};

export default ConfirmTagDeletionDialog;
