import React from "react";
import styles from "../../../construction/Construction.module.scss";
import headerStyles from "./ConstructionCalculationHeader.module.scss";

import type ConstructionCalculationsHeaderProps from "./ConstructionCalculationsHeaderProps";
import CalculationTags from "../tags/CalculationTags";
import CalculationNavigation from "../navigation/CalculationNavigation";
import CalculationCommandBar from "../commandBar/CalculationCommandBar";
import {MessageBar, MessageBarType} from "@fluentui/react";
import {getFromLocalStorage} from "../../../../utils/ManageLocalStorage";
import IsaacPills from "../../../../components/isaacPills/IsaacPills";

const ConstructionCalculationsHeader: React.FunctionComponent<
  ConstructionCalculationsHeaderProps
> = ({
  construction,
  profilesAreValid,
  calculations,
  selectedCalculationResults,
  commandBarItems,
  commandBarFarItems,
}) => {
  const localStorageStatus = getFromLocalStorage<boolean>(
    `${construction?.id}.inProgress`,
  );

  const sourceCalculationId = calculations.find(
    c => c.isUsedToUpdateConstruction,
  )?.calculationId;

  const constructionNameClassName =
    calculations.length > 0
      ? styles.notProcessed
      : profilesAreValid
        ? styles.valid
        : styles.notValid;
  return (
    <>
      {construction && (
        <div className={styles.header}>
          <div className={styles.panelHeader}>
            <div className={styles.panelHeaderContainer}>
              <div className={headerStyles.title}>
                <h2 className={constructionNameClassName}>
                  {construction?.name}
                </h2>
                {selectedCalculationResults?.calculationId !== undefined &&
                  selectedCalculationResults?.calculationId ===
                    sourceCalculationId && (
                    <h3 className={headerStyles.updatedWithResult}>
                      Construction updated with this result
                    </h3>
                  )}
                <CalculationTags construction={construction} />
              </div>
              <CalculationNavigation />
            </div>
          </div>
          <>
            {(construction?.processingNewCalculation ||
              localStorageStatus === true) && (
              <MessageBar messageBarType={MessageBarType.warning}>
                Construction calculation in progress. You will receive a
                notification upon completion.
              </MessageBar>
            )}
          </>
          <IsaacPills
            pills={[
              {name: "Location type", value: construction?.locationType},
              {name: "Location code", value: construction?.locationCode},
              {name: "Phase", value: construction?.phase},
            ]}
          />
          <div className={styles.commandBar}>
            <CalculationCommandBar
              hasCalculations={calculations.length > 0}
              commandBarItems={commandBarItems}
              commandBarFarItems={commandBarFarItems}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ConstructionCalculationsHeader;
