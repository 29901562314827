import React from "react";
import {useDispatch} from "react-redux";

import styles from "../../sidePanel/ConstructionCalculationsPanel.module.scss";
import tabStyles from "./MastTab.module.scss";
import {switchMast} from "../../../constructionCalculationsSlice";
import type IMastTab from "../types/IMastTab";

const MastTab: React.FunctionComponent<{
  tab: IMastTab;
  selectedMastNo: string | undefined;
}> = ({tab, selectedMastNo}) => {
  const dispatch = useDispatch();

  return (
    <div
      key={`${tab.number}`}
      className={
        selectedMastNo !== undefined && tab.number === selectedMastNo
          ? `${styles.mastBtn} ${styles.selected}`
          : styles.mastBtn
      }
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        dispatch(switchMast(tab.number));
      }}>
      <span className={tab.profilesAreValid ? styles.valid : styles.notValid}>
        {tab.number}
      </span>
      <span className={tab.profilesAreValid ? styles.valid : styles.notValid}>
        {tab.type}
      </span>
      <span className={tabStyles.foundationType}>{tab.foundation}</span>
    </div>
  );
};

export default MastTab;
