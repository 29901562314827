import useApi from "../services/api/hooks/useApi";
import ApiHttpVerbs from "../services/api/types/ApiHttpVerbs";
import type ApiResponse from "../services/api/types/ApiResponse";
import Api from "../services/auth/types/IsaacApi";
import type ITagHook from "../types/ITagHook";
import {type Tag} from "../types/Tag";
import {type TagConstruction} from "../types/TagConstruction";

/** Hook that defines all BE calls for the Tag entity. */
export const useTagHook = (): ITagHook => {
  const {fetchData} = useApi();

  const addTagsToConstruction = async (
    constructionId: string,
    tags: Tag[],
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}/tags/`;

    const response = await fetchData<any>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.POST,
      {},
      JSON.stringify(tags),
    );

    return response;
  };

  const getAllTags = async (): Promise<
    ApiResponse<TagConstruction[] | null>
  > => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/tags/`;
    const response = await fetchData<TagConstruction[]>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.GET,
    );

    return response;
  };

  const deleteTagFromConstruction = async (
    constructionId: string,
    tagName: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}/tags/`;
    const response = await fetchData<TagConstruction[]>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.DELETE,
      {},
      JSON.stringify(tagName),
    );

    return response;
  };

  const deleteTagsFromConstructions = async (
    ids: string[],
    tags: string[],
  ): Promise<Array<ApiResponse<any>>> => {
    const promises: Array<Promise<ApiResponse<any>>> = [];

    ids.forEach(id => {
      tags.forEach(tag => {
        promises.push(deleteTagFromConstruction(id, tag));
      });
    });

    return await Promise.all(promises);
  };

  const addTagsToConstructions = async (
    ids: string[],
    tags: string[],
  ): Promise<Array<ApiResponse<any>>> => {
    const promises: Array<Promise<ApiResponse<any>>> = [];

    ids.forEach(id => {
      promises.push(
        addTagsToConstruction(
          id,
          tags.map(t => ({
            Value: t,
          })),
        ),
      );
    });

    return await Promise.all(promises);
  };

  return {
    getAllTags,
    addTagsToConstruction,
    addTagsToConstructions,
    deleteTagFromConstruction,
    deleteTagsFromConstructions,
  };
};

export default useTagHook;
