import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import type {ICommandBarItemProps} from "@fluentui/react";

import type {RootState} from "../../../store";
import type {SignalRMessage} from "../../../services/signalR/models/Messages";
import type IConstruction from "../../construction/types/IConstruction";
import type ICalculation from "../../../types/ICalculation";
import type ICalculationConstructionDetails from "../../../types/ICalculationConstructionDetails";
import type IProfileCalculation from "../../../types/IProfileCalculation";

import useConstructionHook from "../../../hooks/useConstructionHook";
import useCalculationHook from "../../../hooks/useCalculationHook";
import {getOrderByCreatedDate} from "../../../utils/DateFormatting";
import {useAuth} from "../../../services/auth/hooks/useAuth";
import {load as loadConstructions} from "../../constructions/constructionsSlice";
import {load as loadCurrentConstruction} from "../../construction/constructionSlice";
import {load as loadCalculation} from "../constructionCalculationsSlice";
import useSaveModifiedConstructionHook from "./useSaveModifiedConstructionHook";
import ConstructionStatus from "../../../types/ConstructionStatus";
import PathConstants from "../../../routes/pathConstants";
import {getDefaultCommandBarItems} from "../components/commandBar/CommandBarItems";
import IsaacToastWrapperType from "../../../types/IsaacToastWrapperType";
import useIssacToastHook from "../../../hooks/useIssacToastHook";

// Note: this does not take into account that foundations and footplates will also have wildcard
const isConstructionModified = (
  construction: IConstruction | undefined,
  isAllowToTakeAction: boolean,
  selectedCalculationResults: any,
): boolean => {
  if (construction !== undefined) {
    const originalRequestHasMastWildCard = construction.masts?.some(
      m =>
        m.profile?.includes("?") ||
        selectedCalculationResults?.editableConstructionProperties?.length > 0,
    );

    return isAllowToTakeAction && originalRequestHasMastWildCard;
  }
  return false;
};

// TODO: Move state processing logic inside the slice redux actions and simplify
const useConstructionCalculationsPageHook = (): {
  calculations: ICalculation[];
  selectedMastNo: string | undefined;
  selectedCalculationResults: any | undefined;
  constructionDetails: ICalculationConstructionDetails | undefined;
  isAllowToTakeAction: boolean;
  construction: IConstruction | undefined;
  messages: SignalRMessage[];
  sideMenuOpen: boolean;
  commandBarItems: ICommandBarItemProps[];
  commandBarFarItems: ICommandBarItemProps[];
  handleSideMenuOpenState: () => void;
  loadPageData: (selectedCalculationId?: any | null) => Promise<void>;
} => {
  const {id} = useParams();
  const {user} = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const {all} = useSelector((state: RootState) => state.constructions);
  const {data: construction} = useSelector(
    (state: RootState) => state.construction,
  );

  const isConstructionActive =
    all.find(c => c.id === id)?.status !== ConstructionStatus.Frozen;
  const {messages} = useSelector((state: RootState) => state.signalRMessages);

  const {getConstructionById, getAllConstructions} = useConstructionHook();
  const saveModifiedConstruction = useSaveModifiedConstructionHook();
  const {withToast} = useIssacToastHook();

  const {
    getCalculationsByConstructionId,
    getCalculationProfileResults,
    getConstructionDetails,
    downloadExcelInputMatrixById,
    downloadJsonInputMatrixById,
    downloadJsonCalculationConstructionById,
  } = useCalculationHook();
  const {
    calculations,
    selectedMastNo,
    constructionDetails,
    selectedCalculationResults,
    selectedCalculationId,
  } = useSelector((state: RootState) => state.constructionCalculations);

  const isAllowToTakeAction: boolean =
    user?.isAllowedToTakeAction() && isConstructionActive;
  const showSaveModifiedBtn = isConstructionModified(
    construction,
    isAllowToTakeAction,
    selectedCalculationResults,
  );

  const getConstructions = async (): Promise<any[]> => {
    if (all.length <= 0) {
      const response = await getAllConstructions();
      return getOrderByCreatedDate(response?.data ?? []);
    }
    return all;
  };

  const getCurrentConstruction = async (): Promise<IConstruction | null> => {
    if (id !== undefined) {
      const response = await getConstructionById(id);
      return response.data;
    }
    return null;
  };

  const getCalculations = async (): Promise<ICalculation[]> => {
    if (id !== undefined) {
      const calculationsResponse = await getCalculationsByConstructionId(id);

      return (
        calculationsResponse.data?.sort(
          (calculationA: ICalculation, calculationB: ICalculation) =>
            new Date(calculationB.createdOn).getTime() -
            new Date(calculationA.createdOn).getTime(),
        ) ?? []
      );
    }
    return [];
  };

  const getCalculationProfiles = async (
    selectedCalculation: ICalculation | undefined,
    selectedProfileCalculation: IProfileCalculation | undefined,
  ): Promise<any | undefined> => {
    if (
      selectedProfileCalculation !== undefined &&
      selectedCalculation !== undefined
    ) {
      const response = await getCalculationProfileResults(
        selectedCalculation.calculationId,
        selectedProfileCalculation.profileCalculationId,
      );
      return response.data;
    }
    return undefined;
  };

  const loadPageData = async (
    selectedCalculationId: string | null,
  ): Promise<void> => {
    const constructions = await getConstructions();
    const currentConstruction = await getCurrentConstruction();
    const lclCalculations = await getCalculations();

    let lclSelectedCalculation: ICalculation | undefined;
    let lclCurrentProfileCalculationResults: any | undefined;
    let lclConstructionDetails: ICalculationConstructionDetails | undefined;
    let lclCurrentMastNo: string | undefined;
    let lclSelectedProfileCalculation: IProfileCalculation | undefined;

    if (lclCalculations !== undefined && lclCalculations.length > 0) {
      lclSelectedCalculation =
        lclCalculations.find(c => c.calculationId === selectedCalculationId) ??
        lclCalculations[0];
      if (
        lclSelectedCalculation !== undefined &&
        lclSelectedCalculation !== null
      ) {
        lclSelectedCalculation.selected = true;
        lclSelectedProfileCalculation =
          lclSelectedCalculation.profileCalculations[0];

        lclCurrentProfileCalculationResults = await getCalculationProfiles(
          lclSelectedCalculation,
          lclSelectedProfileCalculation,
        );

        const constructionDetailsResponse = await getConstructionDetails(
          lclSelectedCalculation.calculationId,
        );

        lclConstructionDetails =
          constructionDetailsResponse.data?.calculationConstructionDetails;
        lclCurrentMastNo =
          lclCurrentProfileCalculationResults?.mastResults !== undefined &&
          lclCurrentProfileCalculationResults?.mastResults.length > 0
            ? lclCurrentProfileCalculationResults?.mastResults[0]?.mastNumber
            : undefined;
      }
    }

    if (id !== undefined) {
      dispatch(loadConstructions({data: constructions}));
      dispatch(
        loadCurrentConstruction({
          currentConstruction,
          all: all.length > 0 ? all : constructions,
          defaultCalculationParameters: null,
        }),
      );
      dispatch(
        loadCalculation({
          currentCalculationId: lclSelectedCalculation?.calculationId,
          currentProfileCalculationResults: lclCurrentProfileCalculationResults,
          constructionDetails: lclConstructionDetails,
          calculations: lclCalculations,
          currentMastNo: lclCurrentMastNo,
          selectedProfileCalculationId:
            lclSelectedProfileCalculation?.profileCalculationId,
        }),
      );
    }
  };

  const handleSaveModifiedConstruction = async (): Promise<void> =>
    await saveModifiedConstruction(
      id,
      selectedCalculationResults.calculationId as string,
      selectedCalculationResults.mastResults,
      selectedCalculationResults.editableConstructionProperties,
    );

  const handleNavigateToCalculationsNote = (): void =>
    navigate(
      `${PathConstants.Constructions}/${id}/${PathConstants.CalculationsNote}`,
      {state: {calculationId: selectedCalculationResults.calculationId}},
    );

  const handleDownloadExcelInputMatrixTrigger = async (): Promise<void> => {
    if (selectedCalculationId !== undefined) {
      await withToast(
        async () => await downloadExcelInputMatrixById(selectedCalculationId),
        IsaacToastWrapperType.Full,
        new Map<string, string>([["Calculation id", selectedCalculationId]]),
        `Preparing input matrix data for: ${selectedCalculationId}...`,
        "Input matrix downloaded successfully.",
        "Failed to download input matrix!",
      );
    }
  };

  const handleDownloadJsonInputMatrixTrigger = async (): Promise<void> => {
    if (selectedCalculationId !== undefined) {
      await withToast(
        async () => await downloadJsonInputMatrixById(selectedCalculationId),
        IsaacToastWrapperType.Full,
        new Map<string, string>([["Calculation id", selectedCalculationId]]),
        `Preparing input matrix data for: ${selectedCalculationId}...`,
        "Input matrix downloaded successfully.",
        "Failed to download input matrix!",
      );
    }
  };

  const handleDownloadConstructionJsonTrigger = async (): Promise<void> => {
    if (selectedCalculationId !== undefined) {
      await withToast(
        async () =>
          await downloadJsonCalculationConstructionById(selectedCalculationId),
        IsaacToastWrapperType.Full,
        new Map<string, string>([["Calculation id", selectedCalculationId]]),
        `Preparing construction  data for: ${selectedCalculationId}...`,
        "Construction downloaded successfully.",
        "Failed to download construction!",
      );
    }
  };

  const handleSideMenuOpenState = (): void => {
    setSideMenuOpen(prevState => !prevState);
  };

  const {commandBarItems, commandBarFarItems} = getDefaultCommandBarItems(
    showSaveModifiedBtn,
    handleSideMenuOpenState,
    handleSaveModifiedConstruction,
    handleNavigateToCalculationsNote,
    handleDownloadExcelInputMatrixTrigger,
    handleDownloadJsonInputMatrixTrigger,
    handleDownloadConstructionJsonTrigger,
  );

  return {
    isAllowToTakeAction: user?.isAllowedToTakeAction() && isConstructionActive,
    calculations,
    selectedMastNo,
    selectedCalculationResults,
    constructionDetails,
    construction,
    messages,
    sideMenuOpen,
    commandBarItems,
    commandBarFarItems,
    loadPageData,
    handleSideMenuOpenState,
  };
};

export default useConstructionCalculationsPageHook;
