import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import commonEn from "./en/common.json";
import detailsPageEn from "./en/details_page.json";

import commonCode from "./code/common.json";
import detailsPageCode from "./code/details_page.json";

import constructionCalculations from "./en/constructionCalculations_page.json";

const resources = {
  en: {
    common: commonEn,
    detailPage: detailsPageEn,
    constructionCalculations,
  },
  code: {
    common: commonCode,
    detailPage: detailsPageCode,
  },
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
  resources,
  defaultNS: "detailPage",
  ns: ["detailPage", "common", "constructionCalculations"],
  fallbackNS: ["common"],
  fallbackLng: "en",
  debug: false,
  react: {
    useSuspense: false,
  },
});

export default i18n;
