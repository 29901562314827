import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {Stack} from "@fluentui/react";

import styles from "./ConstructionCalculations.module.scss";
import ConstructionCalculationsHeader from "./components/header/ConstructionCalculationsHeader";
import ConstructionCalculationsBody from "./components/body/ConstructionCalculationsBody";
import ConstructionCalculationsPanel from "./components/sidePanel/ConstructionCalculationsPanel";
import IsaacLoading from "../../components/isaacLoading/IsaacLoading";
import {MessageType} from "../../services/signalR/models/Messages";
import useConstructionCalculationsPageHook from "./hooks/useConstructionCalculationsPageHook";
import {clear as clearConstructionCalculations} from "./constructionCalculationsSlice";
import {clear as clearConstruction} from "../construction/constructionSlice";
import {markAsRead} from "../layout/messagesSlice";

const ConstructionCalculations: React.FunctionComponent = () => {
  const {
    messages,
    construction,
    calculations,
    selectedMastNo,
    selectedCalculationResults,
    constructionDetails,
    isAllowToTakeAction,
    sideMenuOpen,
    commandBarItems,
    commandBarFarItems,
    loadPageData,
    handleSideMenuOpenState,
  } = useConstructionCalculationsPageHook();

  const id = useParams();
  const {state} = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const areProfilesValid =
    selectedCalculationResults?.profilesAreValid ?? false;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      setLoading(true);
      await loadPageData(state?.calculationId);
      setLoading(false);
    })();
    return () => {
      dispatch(clearConstruction());
      dispatch(clearConstructionCalculations());
    };
  }, [id]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const relatedMessages = messages.filter(
        m => m.data?.constructionId !== id && !m.isRead,
      );
      if (relatedMessages.length > 0) {
        const firstUnreadMessage = relatedMessages[0];
        if (
          firstUnreadMessage?.type === MessageType.CalculationCompleted ||
          firstUnreadMessage?.type === MessageType.ConstructionUpdated
        ) {
          setLoading(true);
          dispatch(markAsRead(firstUnreadMessage.correlationId ?? ""));
          await loadPageData(
            firstUnreadMessage.data?.id ?? state?.calculationId,
          );
          setLoading(false);
        }
      }
    })();
  }, [messages]);

  return (
    <>
      <IsaacLoading
        loading={loading}
        message="Loading construction calculation..."
      />
      {!loading && (
        <Stack className={styles.bodyStack}>
          <Stack.Item>
            <ConstructionCalculationsHeader
              commandBarItems={commandBarItems}
              commandBarFarItems={commandBarFarItems}
              profilesAreValid={areProfilesValid}
              calculations={calculations}
              construction={construction}
              selectedCalculationResults={selectedCalculationResults}
            />
          </Stack.Item>
          <Stack.Item>
            <ConstructionCalculationsBody
              selectedMastNo={selectedMastNo}
              calculations={calculations}
              construction={construction}
              constructionDetails={constructionDetails}
              profileCalculationResults={selectedCalculationResults}
            />
          </Stack.Item>
          <ConstructionCalculationsPanel
            open={sideMenuOpen}
            setCalculationPanelState={handleSideMenuOpenState}
            calculations={calculations}
            isAllowToTakeAction={isAllowToTakeAction}
          />
        </Stack>
      )}
    </>
  );
};

export default ConstructionCalculations;
