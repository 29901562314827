import React from "react";
import {useDispatch, useSelector} from "react-redux";

import styles from "./Constructions.module.scss";
import {select} from "./constructionsSlice";
import ConstructionsTable from "./components/table/ConstructionsTable";
import IsaacCommandBar from "../../components/isaacCommandBar/IsaacCommandBar";
import ConstructionsFilters from "./components/filters/ConstructionsFilters";
import AddRemoveTagDialog from "./components/tags/AddRemoveTagDialog";
import useConstructionsPageHook from "./hooks/useConstructionsPageHook";
import type {RootState} from "../../store";

const Constructions: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const {page, loading, pagination} = useSelector(
    (state: RootState) => state.constructions,
  );
  const {isTagDialogHidden, tagModalType, setTagDialogHidden, commandBarItems} =
    useConstructionsPageHook();

  const handleSelection = (selectedConstructions: any[]): void => {
    dispatch(select(selectedConstructions));
  };

  return (
    <section className={`${styles.results} ms-motion-slideLeftIn`}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <h2>Constructions</h2>
        </div>
        <div className={styles.commandBar}>
          <IsaacCommandBar items={commandBarItems} farItems={[]} />

          <AddRemoveTagDialog
            type={tagModalType}
            isHidden={isTagDialogHidden}
            handleDialogDismiss={setTagDialogHidden}
          />
        </div>

        <ConstructionsFilters />
        <ConstructionsTable
          loading={loading}
          page={page}
          pagination={pagination}
          handleSelection={handleSelection}
        />
      </div>
    </section>
  );
};

export default Constructions;
