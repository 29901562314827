import React from "react";
import {Toggle} from "@fluentui/react";

import type IsaacToggleButtonProps from "./IsaacToggleButtonProps";

const IsaacToggleButton: React.FunctionComponent<IsaacToggleButtonProps> = ({
  onChange,
  checked,
}) => (
  <Toggle
    checked={checked}
    onText="On"
    offText="Off"
    onChange={(e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      onChange(checked);
    }}
  />
);

export default IsaacToggleButton;
