import React from "react";

import MastTab from "./components/MastTab";
import BeamTab from "./components/BeamTab";
import SpacerTab from "./components/SpacerTab";
import type IMastTab from "./types/IMastTab";
import type IBeamTab from "./types/IBeamTab";
import type ICalculation from "../../../../types/ICalculation";
import type IConstruction from "../../../construction/types/IConstruction";
import useCalculationMastTabs from "./useCalculationMastTabs";

const CalculationMastTabs: React.FunctionComponent<{
  selectedMastNo: string | undefined;
  calculations: ICalculation[];
  construction: IConstruction;
  profileCalculationResults: any;
}> = ({
  selectedMastNo,
  calculations,
  construction,
  profileCalculationResults,
}) => {
  const tabs = useCalculationMastTabs(
    calculations,
    construction,
    profileCalculationResults,
  );
  return (
    <>
      {tabs.map((tab, index) => {
        const isMastTab = (tab: any): tab is IMastTab =>
          (tab as IMastTab).number !== undefined;

        if (isMastTab(tab)) {
          return (
            <MastTab key={index} tab={tab} selectedMastNo={selectedMastNo} />
          );
        }

        const isBeamTab = (tab: any): tab is IBeamTab =>
          (tab as IBeamTab).type !== undefined;
        if (isBeamTab(tab)) {
          return <BeamTab key={index} tab={tab} />;
        }

        return <SpacerTab key={index} />;
      })}
    </>
  );
};

export default CalculationMastTabs;
