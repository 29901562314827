import React from "react";
import {Stack} from "@fluentui/react";

// TODO: Migrate styles to bodyStyles
import styles from "../sidePanel/ConstructionCalculationsPanel.module.scss";
import bodyStyles from "./ConstructionCalculationsBody.module.scss";
import type ConstructionCalculationsBodyProps from "./ConstructionCalculationsBodyProps";
import CalculationMastTabs from "../tabs/CalculationMastTabs";
import CalculationMastResults from "../mastsResults/CalculationMastResults";
import IsaacEmpty from "../../../../components/isaacEmpty/IsaacEmpty";
import EditablePropertiesPanel from "../panelEditableProperties/EditablePropertiesPanel";
import MastProfileAndFoundationPanel from "../panelMastProfileAndFoundation/MastProfileAndFoundationPanel";
import DeselectedElementsPanel from "../panelDeselectedElementsPanel/DeselectedElementsPanel";

const ConstructionCalculationsBody: React.FunctionComponent<
  ConstructionCalculationsBodyProps
> = ({
  construction,
  constructionDetails,
  profileCalculationResults,
  selectedMastNo,
  calculations,
}) => {
  const mastResults = profileCalculationResults?.mastResults?.find(
    (mr: any) => mr.mastNumber === selectedMastNo,
  );

  return (
    <>
      {mastResults !== undefined ? (
        <div className="page-brake-after">
          <h3 className={bodyStyles.resultsHeader}>Results</h3>
          <div className={styles.resultNavigation}>
            {construction && (
              // Note: constructionDetails should be part of profileCalculationResults. Also as a concept profile calculation should be removed.
              <CalculationMastTabs
                construction={construction}
                profileCalculationResults={profileCalculationResults}
                calculations={calculations}
                selectedMastNo={selectedMastNo}
              />
            )}
          </div>
          <Stack className={styles.resultContainer} horizontal wrap>
            <CalculationMastResults mastResults={mastResults} />
            <MastProfileAndFoundationPanel
              constructionDetails={constructionDetails}
              selectedMastNo={selectedMastNo}
              profileCalculationResults={profileCalculationResults}
            />
            <EditablePropertiesPanel
              construction={construction}
              profileCalculationResults={profileCalculationResults}
            />
            <DeselectedElementsPanel
              construction={construction}
              profileCalculationResults={profileCalculationResults}
            />
          </Stack>
        </div>
      ) : (
        <IsaacEmpty message="No data to show for the current calculation." />
      )}
    </>
  );
};

export default ConstructionCalculationsBody;
