import React from "react";
import type {ICommandBarItemProps} from "@fluentui/react";

import styles from "../../ConstructionCalculations.module.scss";
import IsaacCommandBar from "../../../../components/isaacCommandBar/IsaacCommandBar";

const CalculationCommandBar: React.FunctionComponent<{
  hasCalculations: boolean;
  commandBarItems: ICommandBarItemProps[];
  commandBarFarItems: ICommandBarItemProps[];
}> = ({hasCalculations, commandBarItems, commandBarFarItems}) => {
  return (
    <>
      {hasCalculations && (
        <div className={styles.commandBar}>
          <IsaacCommandBar
            items={commandBarItems}
            farItems={commandBarFarItems}
          />
        </div>
      )}
    </>
  );
};

export default CalculationCommandBar;
