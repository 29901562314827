import {createSlice, current} from "@reduxjs/toolkit";

import type {PayloadAction} from "@reduxjs/toolkit";
import type IConstruction from "./types/IConstruction";
import type {EditableProperty} from "../../types/EditableConstruction";
import type {IDefaultConstructionCalculationParameters} from "../../types/IDefaultConstructionCalculationParameters";
import type {SelectedEntity} from "../../types/SelectedEntity";
import core from "../../utils/_legacy/SrvCore";

export interface ConstructionState {
  loading: boolean;
  data: IConstruction | undefined;
  editableProperties: EditableProperty[] | undefined;
  deselectedEntities: SelectedEntity[] | undefined;
  connections: SelectedEntity[] | undefined;
  defaultCalculationParams: IDefaultConstructionCalculationParameters | null;
  nextId: string | null;
  prevId: string | null;
}

const initialState: ConstructionState = {
  loading: true,
  data: undefined,
  editableProperties: undefined,
  deselectedEntities: undefined,
  defaultCalculationParams: null,
  connections: undefined,
  nextId: null,
  prevId: null,
};

export const constructionSlice = createSlice({
  name: "construction",
  initialState,
  reducers: {
    load: (
      state,
      {
        payload,
      }: PayloadAction<{
        currentConstruction: any;
        defaultCalculationParameters: IDefaultConstructionCalculationParameters | null;
        all: any[];
      } | null>,
    ) => {
      if (payload !== null) {
        state.data = payload.currentConstruction;
        state.editableProperties = [];
        state.deselectedEntities = [];
        state.connections = [];
        state.defaultCalculationParams = payload.defaultCalculationParameters;
        const currentIndex = payload.all?.findIndex(
          c => c.id === payload.currentConstruction?.id,
        );

        if (currentIndex !== undefined) {
          state.prevId = payload.all[currentIndex - 1]?.id ?? null;
          state.nextId = payload.all[currentIndex + 1]?.id ?? null;
        }
      }
      state.loading = false;
    },
    removeTag: (state, {payload}: PayloadAction<string>) => {
      if (state.data !== undefined) {
        state.data.tags = state.data?.tags.filter(t => t !== payload);
      }
    },
    addTags: (state, {payload}: PayloadAction<string[]>) => {
      if (state.data !== null) {
        payload.forEach(tag => {
          const index = state.data?.tags.find(t => t === tag);
          if (index === undefined) {
            state.data?.tags.push(tag);
          }
        });
      }
    },
    updateEditableProps: (
      state,
      {payload}: PayloadAction<EditableProperty>,
    ) => {
      state.editableProperties = state.editableProperties?.filter(
        e =>
          e.entityId !== payload.entityId ||
          (e.entityId === payload.entityId &&
            e.propertyName.toLocaleLowerCase() !==
              payload?.propertyName?.toLocaleLowerCase()),
      );

      state.editableProperties?.push({
        ...payload,
        propertyName: core.convertToFirstLetter(payload.propertyName),
        propertyValue: removeMirrorFromValue(payload.propertyValue),
      });

      if (isValueMirrored(payload.propertyValue)) {
        const mirroredEProp = {
          ...payload,
          property: "IsMirrored",
          value: "true",
        };
        state.editableProperties?.push(mirroredEProp);
      }
    },

    updateSelectedEntities: (
      state,
      {payload}: PayloadAction<SelectedEntity>,
    ) => {
      const connections = current(state.connections);
      const childConnections = core.getAllChildren(
        connections ?? [],
        payload.entityId,
      );
      childConnections.push(payload);
      childConnections.forEach(element => {
        const deselectedEntityIndex = state.deselectedEntities?.findIndex(
          (c: any) =>
            c.entityType === element.entityType &&
            c.entityId === element.entityId,
        );

        if (
          deselectedEntityIndex !== undefined &&
          deselectedEntityIndex !== -1
        ) {
          state.deselectedEntities?.splice(deselectedEntityIndex, 1);
        }
      });

      if (!payload.checked) {
        childConnections.forEach(element => {
          state.deselectedEntities?.push(element);
        });
      }
      return state;
    },
    updateConnections: (state, {payload}: PayloadAction<SelectedEntity[]>) => {
      if (payload) {
        state.connections = [];
        state.connections?.push(...payload);
      }
      return state;
    },
    clear: state => {
      state.data = undefined;
    },
  },
});

const removeMirrorFromValue = (value: string | undefined): string => {
  if (value !== undefined) {
    return isValueMirrored(value)
      ? value?.substring(value.indexOf("-") + 1, value.length)
      : checkIfBoolean(value);
  }
  return "";
};

const checkIfBoolean = (value: string | boolean): string => {
  if (typeof value === "boolean") {
    return String(value);
  }
  return value;
};

const isValueMirrored = (value: string | undefined): boolean => {
  if (value !== undefined && typeof value === "string") {
    return value.includes("-");
  }
  return false;
};

export const {
  load,
  removeTag,
  addTags,
  clear,
  updateEditableProps,
  updateSelectedEntities,
  updateConnections,
} = constructionSlice.actions;

export default constructionSlice.reducer;
