import React from "react";
import styles from "./IsaacPills.module.scss";
import type IsaacPillsProps from "./IsaacPillsProps";
import IssacPill from "../isaacPill/IsaacPill";

const IsaacPills: React.FunctionComponent<IsaacPillsProps> = ({pills}) => {
  return (
    <div className={styles.infoBar}>
      {pills.map((pill, index) => (
        <IssacPill key={index} name={pill.name} value={pill.value} />
      ))}
    </div>
  );
};

export default IsaacPills;
