import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {IComboBoxOption} from "@fluentui/react";
import type IPagination from "./types/IPagination";
import type IFilters from "../../types/IFilters";
import type IPayloadConstructionStateUpdate from "./types/IPayloadConstructionStateUpdate";
import {filterData, filterDataWithPagination} from "../../utils/Pagination";
import {setToComboBoxOption} from "../../utils/ComboBoxMapping";

export interface ConstructionsState {
  loading: boolean;
  all: any[];
  page: any[];
  master: any[];
  filters: IFilters;
  tags: IComboBoxOption[];
  createdBy: IComboBoxOption[];
  locationCodes: IComboBoxOption[];
  pagination: IPagination;
}

const initialState: ConstructionsState = {
  loading: true,
  all: [],
  page: [],
  master: [],
  tags: [],
  createdBy: [],
  locationCodes: [],
  filters: {
    name: undefined,
    from: undefined,
    to: undefined,
    locationCodes: [],
    tags: [],
    createdBy: undefined,
  },
  pagination: {
    currentPage: 0,
    pageSize: 15,
    total: 0,
  },
};

const getConstructionsFilterData = (
  constructions: any[],
): [any[], any[], any[]] => {
  const uniqueTags = new Set<string>();
  const uniqueLocationCodes = new Set<string>();
  const uniqueCreatedBy = new Set<string>();

  constructions?.forEach(construction => {
    construction.tags?.forEach((tag: string) => {
      uniqueTags.add(tag);
    });
    uniqueCreatedBy.add(construction.createdBy as string);
    uniqueLocationCodes.add(construction.locationCode as string);
  });

  const tags: IComboBoxOption[] = setToComboBoxOption(uniqueTags);

  const locationCodes: IComboBoxOption[] =
    setToComboBoxOption(uniqueLocationCodes);

  const createdBy: IComboBoxOption[] = setToComboBoxOption(uniqueCreatedBy);

  return [tags, locationCodes, createdBy];
};

// Note: this slice can be improved by moving some logic out for example
// Note: split this slice into at least two
export const constructionsSlice = createSlice({
  name: "constructions",
  initialState,
  reducers: {
    load: (
      state,
      {payload}: PayloadAction<{data: any[]; filters?: IFilters}>,
    ) => {
      state.loading = true;

      if (payload.filters !== undefined) {
        state.filters = payload.filters;
      }

      const filters = payload.filters ?? state.filters;

      state.master = payload.data;
      state.all = filterData(payload.data, filters) ?? [];
      state.page =
        filterDataWithPagination(
          payload.data,
          filters,
          state.pagination.pageSize,
          state.pagination.currentPage,
        ) ?? [];
      state.pagination.total = state.all.length;

      const [tags, locationCodes, createdBy] = getConstructionsFilterData(
        state.master,
      );

      state.tags = tags;
      state.locationCodes = locationCodes;
      state.createdBy = createdBy;

      state.loading = false;
    },

    select: (state, {payload}: PayloadAction<any[] | null>) => {
      if (payload === null) {
        state.page.forEach(item => {
          item.isSelected = false;
        });
        return;
      }

      state.page.forEach(item => {
        item.isSelected = payload.some(
          selectedItem => selectedItem.id === item.id,
        );
      });

      state.page.forEach(item => {
        if (!payload.some(selectedItem => selectedItem.id === item.id)) {
          item.isSelected = false;
        }
      });
    },
    search: (state, {payload}: PayloadAction<IFilters>) => {
      state.loading = true;
      state.filters = payload;

      state.all = filterData(state.master, payload) ?? [];
      state.page =
        filterDataWithPagination(
          state.master,
          payload,
          state.pagination.pageSize,
          0,
        ) ?? [];

      state.pagination.total = state.all.length;
      state.pagination.currentPage = 0;
      state.loading = false;
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
      state.page =
        filterDataWithPagination(
          state.master,
          state.filters,
          state.pagination.pageSize,
          action.payload,
        ) ?? [];
    },
    addTags: (
      state,
      {
        payload,
      }: PayloadAction<{tags: IComboBoxOption[]; constructionId: string}>,
    ) => {
      payload.tags.forEach(tag => {
        const index = state.tags.findIndex(t => t.key === tag.key);
        if (index === -1) {
          state.tags.push(tag);
        }
      });
      const constructionIndex = state.page.findIndex(
        c => c.id === payload.constructionId,
      );

      if (constructionIndex !== -1) {
        payload.tags.forEach((tag: any) => {
          const index = state.page[constructionIndex].tags.findIndex(
            (t: any) => t === tag.key,
          );
          if (index === -1) {
            state.page[constructionIndex].tags.push(tag.key);
          }
        });
      }
    },
    // Note: remove code duplication here
    removeTagFromConstruction: (
      state,
      {payload}: PayloadAction<{tag: string; constructionId: string}>,
    ) => {
      const index = state.page.findIndex(c => c.id === payload.constructionId);
      if (index !== -1) {
        state.page[index].tags = state.page[index].tags.filter(
          (t: string) => t !== payload.tag,
        );
      }
    },
    removeTags: (
      state,
      {
        payload,
      }: PayloadAction<{tags: IComboBoxOption[]; constructionId: string}>,
    ) => {
      const constructionIndex = state.page.findIndex(
        c => c.id === payload.constructionId,
      );

      if (constructionIndex !== -1) {
        payload.tags.forEach((tag: any) => {
          const index = state.page[constructionIndex].tags.findIndex(
            (t: any) => t === tag.key,
          );
          if (index !== -1) {
            state.page[constructionIndex].tags.splice(index, 1);
          }

          const isTagOrphan =
            state.all.filter(c => c.tags.includes(tag.key)).length === 0;

          if (isTagOrphan) {
            const tgIndex = state.tags.findIndex(t => t === tag.key);
            state.tags.splice(tgIndex, 1);
          }
        });
      }
    },
    deleteConstructions: (state, {payload}: PayloadAction<string[]>) => {
      state.all = state.all.filter(
        (c: any) => !payload.includes(c.id as string),
      );
      state.page = state.page.filter(
        (c: any) => !payload.includes(c.id as string),
      );
      state.master = state.master.filter(
        (c: any) => !payload.includes(c.id as string),
      );
    },
    update: (
      state,
      {payload}: PayloadAction<IPayloadConstructionStateUpdate[]>,
    ) => {
      state.all = updateStateCollections(payload, state.all);
      state.page = updateStateCollections(payload, state.page);
      state.master = updateStateCollections(payload, state.master);
    },
  },
});

const updateStateCollections = (
  payload: IPayloadConstructionStateUpdate[],
  state: any[],
): any[] => {
  payload.forEach(item => {
    const index = state.findIndex(a => a.id === item.id);
    if (index !== -1) {
      state[index] = {
        ...state[index],
        status: item.status,
      };
    }
  });

  return state;
};

export const {
  load,
  update,
  select,
  changePage,
  search,
  addTags,
  removeTags,
  removeTagFromConstruction,
  deleteConstructions,
} = constructionsSlice.actions;

export default constructionsSlice.reducer;
