import React from "react";

import styles from "../../sidePanel/ConstructionCalculationsPanel.module.scss";
import type IBeamTab from "../types/IBeamTab";

const BeamTab: React.FunctionComponent<{
  tab: IBeamTab;
}> = ({tab}) => {
  return (
    <div key={`${tab.type}`} className={styles.beam}>
      <span>{tab.type}</span>
    </div>
  );
};

export default BeamTab;
