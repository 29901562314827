import {useAuth} from "../../services/auth/hooks/useAuth";
import type AuthToken from "../../services/auth/types/IAuthToken";
import Api from "../../services/auth/types/IsaacApi";
import useBlob from "../../services/azureBlob/hooks/useBlob";
import BlobContainers from "../../types/BlobContainers";
import useAsyncOperation from "../../utils/hooks/useAsyncOperation";

/** Hook that loads blobs from 'Documents' container
 * @param {string} documentName The name of the document/blob
 * @returns {string | undefined} Document URL
 * @returns {boolean} Loading
 * @returns {Error} Error
 */
const useLoadPdfFromBlob = (
  documentName: string,
): {result: string | undefined; loading: boolean; error: Error | undefined} => {
  const {loadFileFromBlob} = useBlob();
  const {tokens} = useAuth();

  const {result, loading, error} = useAsyncOperation(async () => {
    if (!tokens) {
      throw new Error("No token provided!");
    }
    const storageToken: AuthToken | undefined = tokens.find(
      t => t.api === Api.STORAGE,
    );
    return await loadFileFromBlob(
      BlobContainers.Documents,
      documentName,
      storageToken,
    );
  });

  return {result, loading, error};
};

export default useLoadPdfFromBlob;
