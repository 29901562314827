import {useContext, useState} from "react";
import {useDispatch} from "react-redux";

import {ApiContext} from "../ApiContext";
import type ApiResponse from "../types/ApiResponse";
import type IApiWrapper from "../types/IApiWrapper";
import type Api from "../../auth/types/IsaacApi";

// Note: Think about moving this slice
import {add as addError} from "../../../pages/layout/errorsSlice";
import useIssacToastHook from "../../../hooks/useIssacToastHook";

const useApi = (): IApiWrapper => {
  const context = useContext(ApiContext);

  if (context === undefined) {
    throw new Error("useApi must be used within an ApiProvider");
  }

  const {withToast} = useIssacToastHook();
  const [loading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async <T>(
    url: string,
    api: Api,
    useGlobalToastHandler: boolean = true,
    method: string = "GET",
    customHeaders?: Record<string, string>,
    body?: any,
  ): Promise<ApiResponse<T>> => {
    try {
      const token: string | undefined = context.tokens?.find(
        t => t.api === api,
      )?.accessToken;

      // Note: The use of withToast here might not be ok. This was implemented as such to have a global handling and show an error toast for calls that fail.
      // Note: It can be moved higher inside the hooks that make the actual call.
      const response = useGlobalToastHandler
        ? await withToast(
            async () =>
              await context.fetchData<T>(
                token,
                url,
                method,
                customHeaders,
                body,
              ),
          )
        : await context.fetchData<T>(token, url, method, customHeaders, body);

      return response;
    } catch (error) {
      dispatch(
        addError({
          exception: "Failed to fetch!",
          message: "Isaac services failed to respond.",
        }),
      );
      return {data: null, success: false};
    }
  };

  return {...context, fetchData, loading};
};

export default useApi;
