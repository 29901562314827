import styles from "./IsaacNavigation.module.scss";
import React from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Nav, type INavLinkGroup} from "@fluentui/react";

import PathConstants from "../../routes/pathConstants";
import {useAuth} from "../../services/auth/hooks/useAuth";

const IsaacNavigation: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {signOut, user} = useAuth();

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          key: PathConstants.Constructions,
          url: "",
          name: "Constructions",
          iconProps: {
            iconName: "BuildDefinition",
            className: `${styles.iconItem}`,
          },
          onClick: () => {
            navigate(PathConstants.Home);
          },
        },
      ],
    },
    {
      links: [
        {
          key: "/user",
          name: `${user?.userName} - v${process.env.REACT_APP_VERSION}`,
          url: "",
        },
        {
          key: "/logout",
          name: "logout",
          url: "",
          iconProps: {iconName: "OutOfOffice", className: `${styles.iconItem}`},
          onClick: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            (async () => {
              await signOut();
            })();
          },
        },
      ],
    },
  ];

  return (
    <Nav
      selectedKey={location.pathname}
      initialSelectedKey="dashboard"
      ariaLabel="TUCRail Navigation"
      styles={styles}
      className={`${styles.navContainer} no-print`}
      groups={navLinkGroups}
    />
  );
};

export default IsaacNavigation;
