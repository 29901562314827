import React, {useEffect} from "react";
import {Document, Page, pdfjs} from "react-pdf";

const IsaacPdfViewer: React.FunctionComponent<{url: string}> = ({url}) => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs",
      import.meta.url,
    ).toString();

    return () => {
      pdfjs.GlobalWorkerOptions.workerSrc = "";
    };
  }, []);

  return (
    <Document renderMode="canvas" file={url}>
      {Array.from(Array(5).keys()).map((i, index) => (
        <Page
          key={index}
          pageNumber={i + 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={1200}
        />
      ))}
    </Document>
  );
};

export default IsaacPdfViewer;
